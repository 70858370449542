<template>
  <div>
    <div class="d-flex justify-space-between">
      <h1 class="mb-3">{{ $t('returns') }}</h1>
      <v-btn class="mr-10" large color="secondary" @click="dialog = !dialog">
        <v-icon>mdi-plus</v-icon>
        {{ $t('add-return') }}
        </v-btn
      >
    </div>
    <v-card :loading="loading" class="transparent">
      <v-row>
        <v-col cols="3">
           <label for="se">بحث حسب اسم المسترجع</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            label="اسم المسترجع"
            id="se"
            solo
            filled
            :loading="loading"
            clearable
            v-debounce:500ms="getItems"
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-data-table
            disable-pagination
            :headers="headers"
            :items="items"
            :no-data-text="$t('no-data')"
            :hide-default-footer="true"
            @click:row="
              (_) => {
                $router.push({
                  name: 'accounts-returns-detials',
                  params: {
                    type: type,
                    id: _.id,
                  },
                });
              }
            "
          >
            <template v-slot:item.created="{ item }">
              <span dir="ltr">
                {{ $service.formatDate(new Date(item.created)) }}
              </span>
            </template>
            <template v-slot:item.returnProducts="{ item }">
              <div>{{item.returnProducts[0].pharmacy.fullName}}</div>
            </template>
          </v-data-table>
          <div class="d-flex justify-center">
            <v-pagination
            @input="getItems()"
              v-model="options.PageIndex"
              :length="parseInt(total / options.PageSize) + 1"
              total-visible="7"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
    <Dialog
      :dialog="dialog"
      @updated="
        (_) => {
          dialog = _;
        }
      "
      @refresh="
        (_) => {
          dialog = _;
          getItems();
        }
      "
    />
  </div>
</template>
 
<script>
import Dialog from "./Dialog.vue";
export default {
  components: {
    Dialog,
  },
  computed: {},
  data() {
    return {
      company: {},
      options: {
        CompanyId: "",
        AgentId: "",
        Search: "",
        ReturnStatusId: "",
        PageIndex: 1,
        PageSize: 20,
      },
      returns: [],
      items: [],
      total: 0,

      agents: [],
      companies: [],
      loading: true,
      dialog: false,
      statues: [],
      status: 0,
      headers: [
        {
          text: this.$t('list-no'),
          value: "orderNo",
        },
        // {
        //   text: this.$t('Pharmacy'),
        //   value: "returnProducts",
        // },
        // {
        //   text: "Dr",
        //   value: "returnProducts.pharmacy.fullName",
        // },
        {
          text: this.$t('rururned-products-count'),
          value: "returnProductsCount",
        },
        {
          text: this.$t('order-created-by'),
          value: "orderCreatedBy",
        },
        {
          text: this.$t('created'),
          value: "created",
        },
        {
          text: this.$t('returner-fullname'),
          value: "returnerFullName",
        },
        {
          text: this.$t('order-created-date'),
          value: "orderCreatedDate",
        },
        {
          text: this.$t('region'),
          value: "region",
        },
      ],
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      this.loading = true;
      try {
        let res = await this.$http.get(`Returns?PageIndex=${this.options.PageIndex}&PageSize=${this.options.PageSize}&Search=${this.options.Search}`);
        console.log(res.data.data);
        this.items = res.data.data;
        this.total = res.data.count;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    order(item) {
      if (item.pharmacyOrder !== null) {
        return {
          type: "pharmacy",
          order: item.pharmacyOrder,
          name: item.pharmacy.fullName,
          region: item.pharmacy.regionName,
        };
      } else if (item.doctorOrder !== null) {
        return {
          type: "doctor",
          order: item.doctorOrder,
          name: item.doctor.fullName,
          region: item.doctor.regionName,
        };
      } else if (item.repositoryOrder) {
        return {
          type: "repository",
          order: item.repositoryOrder,
          name: item.repository.fullName,
          region: item.repository.regionName,
        };
      } else {
        return {
          type: "unknown",
          order: item.id,
        };
      }
    },
    getReturns() {
      this.loading = true;
      if (this.status !== this.statues.length) {
        this.options.ReturnStatusId = this.status;
      } else {
        this.options.ReturnStatusId = "";
      }
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http
        .get("/Returns" + filter.replace("?&", "?"))
        .then((prod) => {
          this.returns = prod.data.data;
          this.total = prod.data.count;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  // watch: {
  //     options: {
  //         handler() {
  //             this.getReturns();
  //         },
  //         deep: true,
  //     },
  // },
};
</script>
